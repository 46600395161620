<template>
    <div id="login" :class="[$mq, 'login-content', 'hide-scrollbar']">
        <template v-if="account"> <img class="account-logo" :src="account.avatar" /> </template>
        <div class="login-top">
            <Input
                class="languageInput"
                @change="changeLanguage()"
                :key="lang"
                v-if="languageSelector"
                :gOptions="{ returnObject: false, itemValue: 'code' }"
                gType="select"
                v-model="lang"
                :gItems="Object.values(languageSelector)"
                customClass="login-language"
            ></Input>
        </div>
        <div class="login-container">
            <!-- Login -->
            <template v-if="!bResetPassword && !bNewPassword && !forgotPassword">
                <div v-if="$mq != 'portrait'" class="login-gallery">
                    <div class="gallery-image"></div>
                    <div class="gallery-info">
                        <vue-typed-js :strings="[$t('login.greeting.title')]" :loop="false" :typeSpeed="2" :startDelay="0" :showCursor="false">
                            <span class="titl typing"></span>
                        </vue-typed-js>
                        <vue-typed-js :strings="[$t('login.greeting.title_desc')]" :loop="false" :typeSpeed="2" :startDelay="1" :showCursor="false">
                            <span class="description typing"></span>
                        </vue-typed-js>
                    </div>
                </div>
                <div class="login-form">
                    <template v-if="customAuth">
                        <button class="auth" @click="customAuthLogin()">
                            <img class="icon" :src="account.avatar" />{{ $t('popup.login.auth', { s: account.name }) }}
                        </button>
                    </template>
                    <template v-else>
                        <div class="logo"></div>
                        <div class="form">
                            <div class="form-description">{{ $t('login.greeting.subtitle') }}</div>
                            <div class="error-message" v-show="showError">{{ errorMessage }}</div>

                            <Input
                                gType="text"
                                v-model.trim="loginData.email"
                                :gState="showError ? 'error' : ''"
                                :gLabel="$t('login.form.email')"
                                :gPlaceholder="$t('login.form.email_placeholder')"
                                @keyup.enter="doLogin"
                                gSize="m"
                                :gOptions="{ name: 'email' }"
                            ></Input>
                            <Input
                                gType="password"
                                v-model.trim="loginData.password"
                                :gState="showError ? 'error' : ''"
                                :gLabel="$t('login.form.password')"
                                :gPlaceholder="$t('login.form.pass_placeholder')"
                                @keyup.enter="doLogin"
                                gSize="m"
                                :gOptions="{ name: 'password', type: 'password' }"
                            ></Input>
                        </div>
                        <div class="lost-password" @click="forgotPass()">{{ $t('login.form.lost_pass') }}</div>

                        <Button :bCallback="doLogin" bLabel="login.actions.login" customClass="btn-action submit-login" bTypeOfBtn="submit"></Button>
                    </template>
                </div>
            </template>

            <!-- Recover password -->
            <template v-else-if="forgotPassword">
                <div class="login-form">
                    <div class="login-return" @click="returnBack()">{{ $t('login.greeting.return_back') }}</div>
                    <div class="logo"></div>

                    <div class="form">
                        <div class="form-description">{{ $t('login.greeting.email_desc') }}</div>
                        <p v-if="emptyEmail" class="error-message" v-html="$t('login.form.email_empty')"></p>
                        <p v-if="errorMail" class="error-message" v-html="$t('login.form.email_error')"></p>
                        <p v-if="emailSend" class="success-message" v-html="$t('login.form.remember_success')"></p>
                        <Input
                            gType="text"
                            v-model.trim="forgotEmailSubmit"
                            :gState="emptyEmail || errorMail ? 'error' : ''"
                            :gLabel="$t('login.form.email')"
                            :gPlaceholder="$t('login.form.email_placeholder')"
                        ></Input>
                    </div>
                    <Button :bCallback="forgotPassSubmit" bLabel="login.actions.send" customClass="btn-action submit-login"></Button>
                </div>
            </template>

            <template v-else>
                <!-- VALID LINK -->
                <!-- Resetear password -->
                <template v-if="limit && bResetPassword">
                    <div class="login-form">
                        <div class="login-return" @click="returnBack()">{{ $t('login.greeting.return_back') }}</div>
                        <div class="logo"></div>

                        <div class="form">
                            <div v-if="bResetPassword" class="titl">{{ $t('login.resetPassword.title') }}</div>
                            <div class="form-description">{{ $t('login.resetPassword.subtitle') }}</div>
                            <p class="error-message" v-if="errorsResetPassword.equalPasswords">{{ $t('login.resetPassword.errorequalPasswords') }}</p>
                            <p class="error-message" v-if="errorsResetPassword.password1 || errorsResetPassword.password2">
                                {{ $t('login.resetPassword.errorPassword') }}
                            </p>
                            <p class="error-message" v-if="errorsResetPassword.password2">{{ $t('login.resetPassword.errorPassword') }}</p>

                            <Input
                                gType="password"
                                v-model.trim="resetPasswordData.password1"
                                :gState="errorsResetPassword.password1 || errorsResetPassword.password2 ? 'error' : ''"
                                :gLabel="$t('login.form.password')"
                            ></Input>
                            <Input
                                gType="password"
                                v-model.trim="resetPasswordData.password2"
                                :gState="errorsResetPassword.password2 ? 'error' : ''"
                                :gLabel="$t('login.resetPassword.repeatPassword')"
                                @keyup.enter="doResetPassword"
                            ></Input>

                            <Button
                                :bCallback="doResetPassword"
                                bType=""
                                bLabel="login.resetPassword.confirmChanges"
                                customClass="btn-action submit-login"
                            ></Button>
                        </div>
                    </div>
                </template>

                <!-- CASO DE CREAR CONTRASEÑA DESDE 0 -->
                <template v-if="limit && user_status && bNewPassword && !invitationAccepted">
                    <!-- <div class="login-welcome">
                        <div class="welcome-image"></div>
                        <div class="welcome-info">
                            <span class="titl">{{ $t('login.resetPassword.welcome_title') }}</span>
                            <span class="description">{{ $t('login.resetPassword.welcome_description') }}</span>
                        </div>
                    </div> -->

                    <div class="login-form new-password">
                        <div class="logo"></div>
                        <!-- <div class="business-logo"><img :src="employee_info.account_avatar" /></div> -->

                        <div class="form">
                            <div class="titl">{{ $t('login.newPassword.title') }}</div>
                            <div class="info-message setPassword">{{ $t('login.resetPassword.infoChangeData') }}</div>

                            <div class="user-tag">
                                <div class="user-avatar">
                                    <img :src="cssImage + 'avatar/' + employee_info.avatar" />
                                </div>
                                <div class="user-name">
                                    {{ employee_info.name + ' ' + employee_info.surname }}
                                </div>
                                <div class="user-email">({{ employee_info.email }})</div>
                            </div>

                            <Input
                                :gMessage="{ default: $t('login.resetPassword.minimum_length'), error: $t('login.resetPassword.errorPassword') }"
                                :gState="errorsResetPassword.password1 ? 'error' : ''"
                                gType="password"
                                v-model="resetPasswordData.password1"
                                :gLabel="$t('login.newPassword.password')"
                                gSize="m"
                                :gOptions="{ mandatory: true }"
                            ></Input>
                            <Input
                                gType="password"
                                :gMessage="{ error: $t('login.resetPassword.errorequalPasswords') }"
                                v-model="resetPasswordData.password2"
                                :gState="errorsResetPassword.equalPasswords ? 'error' : ''"
                                :gLabel="$t('login.newPassword.password_repeat')"
                                gSize="m"
                                :gOptions="{ mandatory: true }"
                            ></Input>

                            <Button
                                class="goLogin"
                                :class="{ disabled: this.resetPasswordData.password1.length < 8 || this.resetPasswordData.password2.length < 8 }"
                                customClass="btn-action submit-login"
                                :bCallback="doResetPassword"
                                bLabel="login.newPassword.start"
                            ></Button>
                        </div>
                    </div>
                </template>

                <!-- Invitación ya aceptada -->
                <template v-else-if="invitationAccepted">
                    <div class="login-form">
                        <div class="logo"></div>
                        <!-- <div class="business-logo"><img :src="accountImg" /></div> -->

                        <div class="form">
                            <div class="titl">{{ $t('login.errors.invalid_invitation') }}</div>
                            <div class="form-description">{{ $t('login.errors.invalid_invitation_desc') }}</div>
                            <Button class="goLogin" customClass="btn-action submit-login" :bCallback="goToLogin" bLabel="login.greeting.return_back"></Button>
                            <div class="lost-password" @click="forgotPass()">{{ $t('login.form.lost_pass') }}</div>
                        </div>
                    </div>
                </template>

                <!-- invalit link -->
                <template v-else-if="!limit">
                    <div class="login-form">
                        <div class="logo"></div>
                        <!-- <div class="business-logo"><img :src="accountImg ? accountImg : employee_info.account_avatar" /></div> -->

                        <div class="form">
                            <div class="titl">{{ $t('assets.overlay.timeout.message') }}</div>
                            <div class="form-description">{{ $t('login.resetPassword.expired_title') }}</div>
                            <Button class="goLogin" customClass="btn-action submit-login" :bCallback="goToLogin" bLabel="login.greeting.return_back"></Button>
                        </div>
                    </div>
                </template>

                <!-- USER STATUS INCORRECT -->
                <template v-else-if="!user_status">
                    <div class="login-form" id="login-form3">
                        <div class="form">
                            <div class="titl">{{ $t('login.resetPassword.user_status_title') }}</div>
                            <div class="form-description">{{ $t('login.resetPassword.user_status_body') }}</div>
                        </div>
                        <Button class="goLogin" bType="" customClass="btn-action" :bCallback="goLogin" bLabel="login.greeting.return_back"></Button>
                    </div>
                </template>
            </template>
        </div>
        <div class="login-bottom">
            <AppVersion customClass="login-version"></AppVersion>
            <Copyright customClass="login-copyright"></Copyright>
        </div>

        <!-- Login -->
        <!-- <template v-if="!bResetPassword && !bNewPassword && !forgotPassword">
            <div class="login-gallery"></div>
            <div class="login-form">
                <div class="alex-greeting">{{ $t('login.greeting.title') }}</div>
                <div class="description">{{ $t('login.greeting.title_desc') }}</div>
                <Input @change="changeLanguage()" v-if="languageSelector" :gOptions="{ returnObject: false, itemValue: 'code' }" gType="select" v-model="lang" :gItems="Object.values(languageSelector)" gSize="m"></Input>
                <div class="form">
                    <div class="form-description">{{ $t('login.greeting.subtitle') }}</div>
                    <p class="error" v-show="showError">{{ errorMessage }}</p>
                    <div class="email">
                        <label class="label" for="user">{{ $t('login.form.email') }}</label>
                        <input class="input" :placeholder="$t('user.form.email_placeholder')" type="text" v-model.trim="loginData.email" />
                    </div>
                    <div class="password">
                        <label class="label" for="password">{{ $t('login.form.password') }}</label>
                        <input class="input" type="password" v-model.trim="loginData.password" @keyup.enter="doLogin" :placeholder="$t('login.form.pass_placeholder')" />
                    </div>
                    <div class="actions">
                        <div class="lost-password" @click="forgotPass()">{{ $t('login.form.lost_pass') }}</div>
                        <div class="submit">
                            <Button :bCallback="doLogin" bLabel="login.actions.login" customClass="btn-action submit-login"></Button>
                        </div>
                    </div>
                </div>
            </div>
        </template> -->

        <!-- FORGOT PASSWORD -->
        <!-- <template v-else-if="forgotPassword">
            <div class="gallery forgot"></div>
            <div class="login-form">
                <div class="back-button" @click="returnBack()">{{ $t('login.greeting.return_back') }}</div>
                <div class="alex-greeting">{{ $t('login.greeting.email') }}</div>
                <div class="form forgot">
                    <div class="form-description">{{ $t('login.greeting.email_desc') }}</div>
                    <p v-if="emptyEmail" class="error-form" v-html="$t('login.form.email_empty')"></p>
                    <p v-if="errorMail" class="error-form" v-html="$t('login.form.email_error')"></p>
                    <p v-if="emailSend" class="success" v-html="$t('login.form.remember_success')"></p>
                    <div class="form-input-container">
                        <div class="email">
                            <label class="label" for="email">{{ $t('login.form.email') }}</label>
                            <input class="input" :placeholder="$t('user.form.email_placeholder')" type="text" v-model.trim="forgotEmailSubmit" />
                        </div>
                        <div class="submit">
                            <Button :bCallback="forgotPassSubmit" bLabel="login.actions.send" customClass="btn-action submit-login"></Button>
                        </div>
                    </div>
                </div>
            </div>
        </template> -->
        <!-- Change Password -->
        <!-- <template v-else> -->
        <!-- valid link -->

        <!-- RESETEAR PASSWORD -->
        <!-- <template v-if="limit && bResetPassword">
                <div class="login-gallery2"></div>

                <div class="login-form">
                    <div v-if="bResetPassword" class="alex-greeting">{{ $t('login.resetPassword.title') }}</div>
                    <div class="form">
                        <div class="form-description">{{ $t('login.resetPassword.subtitle') }}</div>
                        <p class="error" v-if="errorsResetPassword.equalPasswords">{{ $t('login.resetPassword.errorequalPasswords') }}</p>
                        <div class="password element1">
                            <label class="label" for="password">{{ $t('login.form.password') }}</label>
                            <p class="error" v-if="errorsResetPassword.password1 || errorsResetPassword.password2">{{ $t('login.resetPassword.errorPassword') }}</p>
                            <input class="input" type="password" v-model.trim="resetPasswordData.password1" />
                        </div>
                        <p class="error" v-if="errorsResetPassword.password2">{{ $t('login.resetPassword.errorPassword') }}</p>
                        <div class="password2">
                            <div class="password element2">
                                <label class="label" for="password">{{ $t('login.resetPassword.repeatPassword') }}</label>
                                <input class="input" type="password" v-model.trim="resetPasswordData.password2" @keyup.enter="doResetPassword" />
                            </div>
                            <div class="actions">
                                <div class="lost-password"></div>
                                <div class="submit">
                                    <Button :bCallback="doResetPassword" bType="" bLabel="login.resetPassword.confirmChanges" customClass="btn-action"></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template> -->

        <!-- CASO DE CREAR CONTRASEÑA DESDE 0 -->
        <!-- <template v-if="limit && user_status && bNewPassword && !invitationAccepted">
                <div class="reset-password-container">
                    <div class="business-logo"><img :src="employee_info.account_avatar" /></div>
                    <div class="welcome-container">
                        <div class="andy-logo-welcome"></div>
                        <div class="welcome-phrase">
                            <div class="welcome-title">{{ $t('login.resetPassword.welcome_title') }}</div>
                            <div class="welcome-description">{{ $t('login.resetPassword.welcome_description') }}</div>
                        </div>
                    </div>
                    <div class="form-container">
                        <div class="user-tag">
                            <div class="user-avatar">
                                <img :src="cssImage + 'avatar/' + employee_info.avatar" />
                            </div>
                            <div class="user-name">
                                {{ employee_info.name + ' ' + employee_info.surname }}
                            </div>
                            <div class="user-email">({{ employee_info.email }})</div>
                        </div>
                        <div class="password element1">
                            <Input :gMessage="{ default: $t('login.resetPassword.minimum_length'), error: $t('login.resetPassword.errorPassword') }" :gState="errorsResetPassword.password1 ? 'error' : ''" gType="password" v-model="resetPasswordData.password1" :gLabel="$t('login.form.password')" gSize="m"></Input>
                        </div>
                        <div class="password element2">
                            <Input gType="password" :gMessage="{ error: $t('login.resetPassword.errorequalPasswords') }" v-model="resetPasswordData.password2" :gState="errorsResetPassword.equalPasswords ? 'error' : ''" :gLabel="$t('login.resetPassword.repeatPassword')" gSize="m"></Input>
                        </div>
                        <Button class="goLogin" :class="{ disabled: this.resetPasswordData.password1.length < 8 || this.resetPasswordData.password2.length < 8 }" customClass="btn-action" :bCallback="doResetPassword" bLabel="login.resetPassword.start"></Button>
                        <div class="info-box setPassword">{{ $t('login.resetPassword.infoChangeData') }}</div>
                    </div>
                </div>
            </template> -->
        <!-- Invitación ya aceptada -->
        <!-- <template v-else-if="invitationAccepted">
                <div class="reset-password-container">
                    <div class="business-logo"><img :src="accountImg" /></div>
                    <div class="columns-container">
                        <div class="andy-img-face"></div>
                        <div class="content-container">
                            <div class="welcome-phrase">
                                <div class="welcome-title">{{ $t('login.errors.invalid_invitation') }}</div>
                            </div>
                            <div class="form">
                                <div class="msg">
                                    {{ $t('login.errors.invalid_invitation_desc') }}
                                </div>
                                <Button class="goLogin" customClass="btn-action" :bCallback="goToLogin" bLabel="login.form.access_control_panel"></Button>
                                <div class="forgot" @click="forgotPass">
                                    {{ $t('login.form.lost_pass') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template> -->

        <!-- invalit link -->
        <!-- <template v-else-if="!limit">
                <div class="reset-password-container">
                    <div class="business-logo"><img :src="accountImg ? accountImg : employee_info.account_avatar" /></div>
                    <div class="columns-container">
                        <div class="andy-img-face"></div>
                        <div class="content-container">
                            <div class="welcome-phrase">
                                <div class="welcome-title">{{ $t('assets.overlay.timeout.message') }}</div>
                            </div>
                            <div class="form">
                                <div class="msg">
                                    {{ $t('login.resetPassword.expired_title') }}
                                </div>
                                <Button class="goLogin" customClass="btn-action" :bCallback="goToLogin" bLabel="login.form.return_to_control_panel"></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </template> -->

        <!-- USER STATUS INCORRECT -->
        <!-- <template v-else-if="!user_status">
                <div class="login-gallery3"></div>

                <div class="login-form" id="login-form3">
                    <div class="alex-greeting">{{ $t('login.resetPassword.user_status_title') }}</div>
                    <div class="form">
                        <div class="form-description">{{ $t('login.resetPassword.user_status_body') }}</div>
                    </div>

                    <Button class="goLogin" bType="" customClass="btn-action" :bCallback="goLogin" bLabel="login.resetPassword.goLogin"></Button>
                </div>
            </template> -->
        <!-- </template> -->
    </div>
</template>

<script>
import UserTag from '../components/ui/UserTag.vue'
import Copyright from '@/components/ui/Copyright'
import AppVersion from '@/components/ui/AppVersion'
import localforage from 'localforage'
import i18n from '@/i18n'

import { Api } from '@/core/api.js'
const api = new Api()

export default {
    name: 'Login',
    components: { Copyright, AppVersion, UserTag },
    data() {
        return {
            localeLangs: {
                es: 'es_ES',
                en: 'en_US',
                de: 'de_DE',
                pt: 'pt_PT'
            },
            invitationAccepted: false,
            account: false,
            accountImg: false,
            customAuth: false,
            cssImage: css_image_url,
            formPage: false,
            showError: false,
            errorMessage: '',
            loginData: {
                email: '',
                password: ''
            },
            emailSend: false,
            errorMail: false,
            forgotEmailSubmit: '',
            forgotPassword: false,
            newPassword: false,
            year: moment().format('YYYY'),
            // change password
            bResetPassword: false,
            bNewPassword: false,
            employeeId: false,
            employee_info: false,
            limit: null,
            lang: [],
            resetPasswordData: {
                password1: '',
                password2: ''
            },
            errorsResetPassword: {
                password1: false,
                password2: false,
                equalPasswords: false
            },
            emptyEmail: false,
            user_status: true,
            invitationId: false
        }
    },
    computed: {
        languageSelector() {
            return this.$store.getters['getAvailableLanguages']
        }
    },
    created() {
        var self = this
        this.$store.commit('login/logout')
        this.$store.dispatch('resetStore')
        this.$store.dispatch('languages')
        this.lang = typeof localStorage.language !== 'undefined' ? this.localeLangs[localStorage.language] : 'es_ES'
        this.changeLanguage()
        this.resetPasswordPage()

        let account_prefix = typeof this.$route.params.account !== 'undefined' ? this.$route.params.account : false
        api.get('login/account/' + account_prefix).then(function (response) {
            self.account = response.data
            self.customAuth = response.data.config && response.data.config.callLoginUsers ? response.data.config.callLoginUsers == 1 : false
        })
    },
    watch: {
        'loginData.email': function (newVal) {
            this.validateEmail(newVal)
        }
    },
    mounted() {
        document.getElementById('logincontent').className = 'inLogin'
    },
    methods: {
        changeLanguage() {
            if (this.lang) i18n.loadLanguageAsync(this.lang.length > 2 ? this.lang.substring(0, 2) : this.lang)
        },
        goToLogin() {
            this.bResetPassword = false
            this.bNewPassword = false
            this.forgotPassword = false
            this.$router.push('/login')
        },
        forgotPassSubmit() {
            if (this.forgotEmailSubmit != '') {
                this.emptyEmail = false
            } else {
                this.emptyEmail = true
            }

            if (!this.emptyEmail) {
                let self = this
                self.$overlay.loading()
                this.$store
                    .dispatch('employee/emailForgot', {
                        email: this.forgotEmailSubmit,
                        lang: typeof localStorage.language !== 'undefined' ? localStorage.language : 'es'
                    })
                    .then(function (response) {
                        if (!response.status) {
                            self.errorMail = true
                        } else {
                            self.errorMail = false
                            self.emailSend = true
                        }
                        self.$overlay.hide()
                    })
            }
        },
        forgotPass() {
            this.$router.push('/login')
            this.forgotPassword = true
        },
        returnBack() {
            this.forgotPassword = false
            this.forgotEmailSubmit = ''
        },
        showForm: function () {
            this.formPage = true
        },
        showDetail: function () {
            this.formPage = false
        },
        doLogin: function (event) {
            // event.preventDefault()
            // event.stopPropagation()

            var self = this
            self.showError = false

            var validation = []
            var email = self.loginData.email
            var password = self.loginData.password

            if (email == '' || email.length <= 0) validation.push(false)
            if (password == '' || password.length <= 0) validation.push(false)

            if (validation.indexOf(false) == -1) {
                self.$overlay.show()
                self.$store
                    .dispatch('login/loginMultiAccount', {
                        email: email,
                        password: sha256(password)
                    })
                    .then(function (response) {
                        if (response.status) {
                            if (Object.keys(response.data.accounts).length == 1) {
                                self.$store.commit('login/setEmployeeMultiaccount', false)
                                self.$router.push('home')
                            } else {
                                self.$router.push('multiaccount')
                            }
                        } else {
                            if (response.redirect) {
                                self.showError = false
                            } else {
                                const blackListData = self.$store.getters['login/getBlackListData']
                                let errorMessage = self.$t('login.errors.access')

                                if (blackListData.warning) {
                                    errorMessage = self.$t('login.errors.blacklist.warning')
                                }

                                if (blackListData.blocked) {
                                    errorMessage = self.$t('login.errors.blacklist.blocked')
                                }

                                self.showError = true
                                self.errorMessage = errorMessage
                            }
                        }
                        self.$overlay.hide()
                    })
                    .catch((error) => {
                        console.error(error)
                        self.$overlay.hide()
                    })
            } else {
                self.showError = true
                self.errorMessage = self.$t('login.errors.input')
            }
        },
        validateEmail: function (email) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            if (!emailPattern.test(email)) {
                this.errorMessage = this.$t('login.errors.error_format_mail')
                this.showError = true
            } else {
                this.errorMessage = ''
                this.showError = false
            }
        },
        customAuthLogin() {
            this.$store.dispatch('login/externalLogin', this.account.id).then((response) => {
                if (response.status) {
                    window.location.href = response.data
                }
            })
        },
        goLogin() {
            window.location.href = 'login'
        },
        resetPasswordPage() {
            const self = this

            // check if url is a reset password
            if (this.$route.name == 'ResetPassword' || this.$route.name == 'dashboardAccess') {
                this.bResetPassword = this.$route.name == 'ResetPassword'
                this.bNewPassword = this.$route.name == 'dashboardAccess'
                this.invitationId = this.$route.params.id
                const resetPassword = this.$route.name == 'ResetPassword' ? 1 : 0

                this.$store.dispatch('login/getInvitationInformation', { sInvitationId: this.invitationId }).then((response) => {
                    if (response.status) {
                        self.lang = self.localeLangs[response.data.lang]
                        self.accountImg = response.data.account_avatar
                        self.$store.commit('changeLanguage', response.data.lang)

                        if (response.data.status == 3) {
                            self.invitationAccepted = true
                            return
                        }

                        if (response.data.status == -1) {
                            self.invitationAccepted = false
                            return
                        }

                        self.limit = moment().valueOf() <= moment(response.data.expiration_date * 1000).valueOf()
                        self.employeeId = response.data.employee_id

                        if (self.$bResetPassword) {
                            self.$store.dispatch('login/checkResetPassword', { id: this.employeeId }).then((response) => {
                                self.user_status = response
                            })
                        }

                        self.$store.dispatch('employee/employeeNewInfo', { id: self.employeeId, resetPassword: resetPassword }).then((response) => {
                            if (response.status) {
                                self.employee_info = response.data
                            } else if (response.error.code == -3) {
                                self.invitationAccepted = true
                                self.accountImg = response.error.account_avatar
                            }
                        })
                    }
                })
            }
        },
        doResetPassword() {
            let validate = true

            // validate password1 length
            if (this.resetPasswordData.password1.length >= 8 && this.resetPasswordData.password2.length >= 8) {
                if (validate && this.resetPasswordData.password1.length < 8) {
                    this.errorsResetPassword.password1 = true
                    validate = false
                } else {
                    this.errorsResetPassword.password1 = false
                }

                // validate password2 length
                if (this.resetPasswordData.password2.length < 8) {
                    this.errorsResetPassword.password2 = true
                    validate = false
                } else {
                    this.errorsResetPassword.password2 = false
                }
                // if password 1 and password 2 are valit
                if (this.resetPasswordData.password1 != this.resetPasswordData.password2) {
                    this.errorsResetPassword.equalPasswords = true
                    validate = false
                } else {
                    this.errorsResetPassword.equalPasswords = false
                }

                // if validate, change password
                if (validate) {
                    const db_updates = localforage.createInstance({ name: 'ALEXCENTRAL', storeName: 'updates' })
                    const db_templates = localforage.createInstance({ name: 'ALEXCENTRAL', storeName: 'templates' })
                    const db_employees = localforage.createInstance({ name: 'ALEXCENTRAL', storeName: 'employee' })
                    const db_logbook = localforage.createInstance({ name: 'alexdb', storeName: 'logbook' })
                    const db_categories = localforage.createInstance({ name: 'alexdb', storeName: 'product_categories' })
                    const db_products = localforage.createInstance({ name: 'alexdb', storeName: 'products' })

                    let self = this
                    this.$store
                        .dispatch('login/editPassword', {
                            id: this.employeeId,
                            password: sha256(this.resetPasswordData.password2),
                            type: this.bNewPassword ? 1 : 2,
                            sInvitationId: this.invitationId
                        })
                        .then(function (response) {
                            self.bResetPassword = false
                            self.bNewPassword = false
                            db_updates.clear()
                            db_templates.clear()
                            db_employees.clear()
                            db_logbook.clear()
                            db_categories.clear()
                            db_products.clear()
                            if (Object.keys(response.data.accounts).length == 1) {
                                self.$store.commit('login/setEmployeeMultiaccount', false)
                                self.$router.push('/home')
                            } else {
                                self.$router.push('/multiaccount')
                            }
                        })
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#login.login-content {
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items();
    @include justify-content();

    height: 100%;
    width: fit-content;
    min-width: 500px;
    overflow: auto;
    padding: 30px;

    .account-logo {
        height: 50px;
    }

    .login-top,
    .login-bottom {
        @include display-flex();
        @include flex-direction(row);
        @include justify-content(space-between);
        height: 30px;
        width: 100%;
    }
    .login-top {
        @include justify-content(flex-end);
        margin-bottom: 6px;
    }
    .languageInput {
        padding-left: 18px;
        width: 150px;
        .v-text-field {
            background-color: transparent !important;
        }
    }
    .login-bottom {
        margin-top: 6px;
        .login-version,
        .login-copyright {
            color: $color-neutral-500;
        }
    }

    .login-container {
        @include default-box-shadow($o: 0.1, $b: 9px);
        @include border-radius(12px);
        @include display-flex();
        background-color: $color-white;
        padding: 30px 0;

        .error-message,
        .success-message,
        .info-message {
            @include border-radius(3px);
            @include font-size(xs);
            font-family: $text-bold;
            color: $color-neutral-600;
            background-color: $color-neutral-200;
            padding: 6px 9px;
            margin-top: 15px;
        }
        .error-message {
            color: $color-error-800;
            background-color: $color-error-100;
        }
        .success-message {
            color: $color-success-500;
            background-color: $color-success-100;
        }
        .info-message {
            color: $color-info-500;
            background-color: $color-info-100;
        }

        .login-gallery {
            @include background($image: img('ellipse_second_opacity_5.svg'));
            @include display-flex();
            @include justify-content();
            @include align-items();
            @include flex-direction(column);
            width: 350px;
            padding: 60px;
            margin: 15px 30px 15px 60px;

            &.forgot {
            }

            .gallery-image {
                @include background($image: img('andy_carita_06.svg'));
                min-width: 200px;
                min-height: 125px;
            }
            .gallery-info {
                margin-top: 9px;

                .titl,
                .description {
                    display: block;
                    width: 100%;
                }

                .titl {
                    @include font-size(lg);
                    font-family: $text-bold;
                    color: $color-black;
                }
                .description {
                    @include font-size(md);
                    font-family: $text-medium;
                    color: $color-neutral-600;
                }
            }
        }

        .login-welcome {
            @include background($image: img('ellipse_second_opacity_5.svg'));
            @include display-flex();
            @include justify-content();
            @include align-items();
            @include flex-direction(column);
            width: 350px;
            padding: 60px;
            margin: 15px 30px 15px 60px;

            &.forgot {
            }

            .welcome-image {
                @include background($image: img('andy_carita_06.svg'));
                min-width: 200px;
                min-height: 125px;
            }
            .welcome-info {
                margin-top: 9px;

                .titl,
                .description {
                    display: block;
                    width: 100%;
                }

                .titl {
                    @include font-size(lg);
                    font-family: $text-bold;
                    color: $color-black;
                }
                .description {
                    @include font-size(md);
                    font-family: $text-medium;
                    color: $color-neutral-600;
                }
            }
        }

        .login-form {
            @include display-flex();
            @include justify-content();
            @include flex-direction(column);
            width: 425px;
            padding: 15px 30px;
            margin: 15px 30px;

            .auth {
                border: 1px solid $color-neutral-300;
                color: $color-black;
                text-align: left;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 25px 5px;
                box-shadow: 1px 1px 5px $color-neutral-200;
                background-color: $color-black;
                color: #fff;
                line-height: 18px;

                .icon {
                    height: 40px;
                    margin-right: 10px;
                    background-color: #fff;
                    padding: 6px;
                    border-radius: 3px;
                }
            }

            .logo {
                @include background($image: img('logo_andy.svg'), $position: left center);
                width: 100%;
                height: 30px;
                margin-bottom: 30px;
            }

            .business-logo {
                width: auto;
                height: 30px;
                // margin-top: 20px;
            }

            .login-return {
                @extend .interaction;
                @include background($image: img('left_arrow_neutro_s50.svg'), $position: left center, $size: 15px);
                @include font-size(md);
                font-family: $text-medium;
                color: $color-neutral-600;
                width: 100%;
                padding-left: 20px;
                margin-bottom: 40px;
            }

            .user-tag {
                @include display-flex();
                @include align-items();
                width: 100%;

                .user-avatar,
                .user-name,
                .user-email {
                    display: inline-block;
                }

                .user-avatar {
                    width: 30px;
                    height: 30px;
                }
                .user-name,
                .user-email {
                    @include font-size(md);
                    color: $color-black;
                    font-family: $text-medium;
                    margin-left: 6px;
                }
                .user-email {
                    font-family: $text-bold;
                }
            }

            .form {
                .titl {
                    @include font-size(ml);
                    font-family: $text-bold;
                    color: $color-black;
                    margin-bottom: 6px;
                }
                .form-description {
                    @include font-size(md);
                    font-family: $text-medium;
                    color: $color-neutral-600;
                }

                .input-container {
                    margin: 0;
                    margin-top: 15px;
                    width: 140px;
                }
            }

            .lost-password {
                @extend .interaction;
                @include font-size(sm);
                font-family: $text-bold;
                color: $color-primary-500;
                text-decoration: underline;
                margin-top: 30px;
            }
            .submit-login {
                margin-top: 30px;
            }

            &.forgot {
            }

            &.new-password {
                .titl {
                    @include font-size(lg);
                }

                .info-message {
                    margin: 6px 0 20px 0;
                }
            }
        }
    }

    &.portrait {
        min-width: 0;
        padding: 16px;

        .login-container {
            width: 100%;
            margin: 0 auto;
            border-radius: 4px;

            .login-form {
                margin: 8px;
                width: 100%;
                padding: 8px;

                .logo {
                    background-position: center;
                }

                .form {
                    .input-container {
                        width: 100%;
                        margin-top: 8px;

                        input {
                            height: 45px !important;
                        }
                    }
                    .form-description {
                        color: unset;
                        text-align: center;
                        margin-bottom: 16px;
                    }
                }

                .lost-password {
                    margin-top: 8px;
                }
                button {
                    margin-top: 16px;
                    width: 100%;
                    height: 45px;
                }
            }
        }
    }
}
</style>
